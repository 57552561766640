





























































































































import { Bind, Debounce } from 'lodash-decorators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { isEqual } from 'lodash'

import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import DateTimeInput from '@/components/_uikit/controls/DateTimeInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import {
  IMentorLessonsTable,
  IMentorLessonsTableType,
  LessonCloneData,
  LessonShortResource,
  NameValueChildrenResource,
} from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'
import AuthModule from '@/store/modules/auth'
import MentorLessonsModule from '@/store/modules/mentor/lessons'
import MentorEducationModule from '@/store/modules/mentor/education'
import LinkPrimary from '@/components/_uikit/links/LinkPrimary.vue'
import CourseGroupMonthChainMixin from '@/mixins/CourseGroupMonthChainMixin'
import { convertDateToMSK } from '@/utils/functions'

@Component({
  components: {
    CourseSearch,
    DateTimeInput,
    LinkPrimary,
    Select,
    TableFooter,
    Tag,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class SelectLessonTemplateModal extends Mixins(NotifyMixin, CourseGroupMonthChainMixin) {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private courseId!: number

  @Prop({ default: null })
  private groupId!: number

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private footerOptions = tableFooterOptions

  private selectedLessons: LessonShortResource[] = []
  private addedToCourseLessons: LessonShortResource[] = []
  private showAddedToCourse = true

  private get form (): LessonCloneData {
    return {
      courseId: this.courseId,
      ignorePubDate: false,
      spendingAt: '',
      startPublishedAt: '',
    }
  }

  private get filter() {
    return MentorLessonsModule.lessonsListFilter
  }

  private set filter (filter: IMentorLessonsTable) {
    if (!isEqual(filter, this.filter) || this.lessons.length === 0) {
      MentorLessonsModule.setLessonsListFilter(filter)
      this.fetchLessons()
    }
  }

  private get lessons() {
    return MentorLessonsModule.lessonsList.data
  }

  private get meta() {
    return MentorLessonsModule.lessonsList.meta
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private get headers () {
    return [
      { cellClass: 'cell-width-30', text: 'Урок', value: 'title' },
      { cellClass: 'cell-width-12', text: 'Запись урока', value: 'lessonVideoAvailable' },
      { cellClass: 'cell-width-30', text: 'Курс', value: 'course' },
      { text: 'Предмет', value: 'subject' },
    ]
  }

  private _handleChangeCourse(course: NameValueChildrenResource, callback: any) {
    this.handleChangeCourse(course, callback)
    this.handleFilter('courseId', course ? course.value : null)
  }

  private onCourseChange () {
    this.filter.monthId = undefined
  }

  private fetchMasterGroupByCourseWithoutSideEffect() {
    MentorEducationModule.fetchMasterGroupByIdWithoutSideEffect(this.filter.courseId as number)
      .catch(this.notifyError)
  }

  private handleFilter(field: IMentorLessonsTableType, value: any) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private copyLesson() {
    MentorLessonsModule.copyLesson({
      body: this.selectedLessons[0].hasVideo || this.selectedLessons[0].videoUrl
        ? {
          ...this.form,
          spendingAt: convertDateToMSK(this.form.spendingAt as string, this.isLocalTimezone),
          startPublishedAt: convertDateToMSK(this.form.startPublishedAt as string, this.isLocalTimezone),
        }
        : {
          ...this.form,
          ignorePubDate: undefined,
          spendingAt: convertDateToMSK(this.form.spendingAt as string, this.isLocalTimezone),
          startPublishedAt: undefined,
        },
      lessonId: this.selectedLessons[0].id,
    })
      .then(() => {
        const form: any = this.$refs.form
        this.notifyInfo(`Урок «${this.selectedLessons[0].title}» добавлен в курс`)
        this.addedToCourseLessons.push(this.selectedLessons[0])
        this.selectedLessons = []
        this.form.spendingAt = ''
        this.form.startPublishedAt = ''
        requestAnimationFrame(() => (form.reset()))
      })
      .catch(this.notifyError)
  }

  private handleSelect() {
    if (this.selectedLessons.length) {
      const form: any = this.$refs.form

      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            this.copyLesson()
          } else {
            this.notifyError('Проверьте введеные данные')
          }
        })
    } else {
      this.notifyError('Выберите задание')
    }
  }

  private handleClose () {
    if (this.addedToCourseLessons.length) {
      this.$emit('closed-modal')
    }
    this.addedToCourseLessons = []
    this.$emit('update:visible', false)
  }

  @Bind
  @Debounce(300)
  private fetchLessons() {
    MentorLessonsModule.fetchLessonsList()
      .catch(this.notifyError)
  }
}
