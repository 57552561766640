




































































































import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import SelectExerciseTemplateModal from '@/components/modals/exercise/SelectExerciseTemplateModal.vue'
import SelectLessonTemplateModal from '@/components/modals/lessons/SelectLessonTemplateModal.vue'
import Tag from '@/components/_uikit/Tag.vue'
import CourseProgramMonthMixin from '@/mixins/manager/CourseProgramMonthMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import { NameValueResource } from '@/store/types'
import Parameters from '@/components/_uikit/Parameters.vue'
import ManagerCoursesModule from '@/store/modules/manager/courses'

enum MenuActionExercises {
  CREATE = 'create',
  TEMPLATE = 'template',
}

enum MenuActionLessons {
  CREATE = 'create',
  TEMPLATE = 'template',
}

@Component({
  components: {
    LinkTabs,
    Parameters,
    SelectExerciseTemplateModal,
    SelectLessonTemplateModal,
    Tag,
  },
})
export default class CoursesItemProgramMonth extends Mixins(CourseProgramMonthMixin, PermissionsMixin) {
  private showExerciseTemplateModal = false
  private showLessonsTemplateModal = false

  private get breadcrumbs () {
    return this.month ? [
      { name: 'Курсы', path: '/manager/control/courses' },
      { name: this.courseTitle, path: `/manager/control/courses/${this.month.course.id}/default/program` },
    ] : []
  }

  private get tabs () {
    return this.month ? [
      {
        name: 'Уроки',
        to: `/manager/control/courses/${this.month.course.id}/program/${this.month.id}/lessons`,
      },
      {
        name: 'Домашняя работа',
        to: `/manager/control/courses/${this.month.course.id}/program/${this.month.id}/exercises`,
      },
    ] : []
  }

  private get exercisesMenu (): NameValueResource[] {
    return [
      { name: 'Выбрать шаблон', value: MenuActionExercises.TEMPLATE },
      { name: 'Создать новое', value: MenuActionExercises.CREATE },
    ]
  }

  private get lessonsMenu() {
    return [
      { name: 'Создать урок', value: MenuActionLessons.CREATE },
      { name: 'Выбрать из курса', value: MenuActionLessons.TEMPLATE },
    ]
  }

  private destroyed() {
    this.$bus.$off('add-exercise-from-template', this.fetchMonth as any)
  }

  private fetchLessons () {
    ManagerCoursesModule.fetchLessons({
      courseID: this.courseID,
      monthID: this.monthID,
    })
      .catch(this.notifyError)
  }

  private handleExerciseMenuClick (option: NameValueResource) {
    switch (option.value) {
    case MenuActionExercises.TEMPLATE:
      this.showExerciseTemplateModal = true
      break
    case MenuActionExercises.CREATE:
      this.$router.push({
        name: 'manager.control.courses.item.program.month.exercises.new',
        params: {
          courseID: this.$route.params.courseID,
          monthID: this.$route.params.monthID,
        },
      })
      break
    }
  }

  private handleLessonsMenuClick (option: NameValueResource) {
    switch (option.value) {
    case MenuActionLessons.TEMPLATE:
      this.showLessonsTemplateModal = true
      break
    case MenuActionLessons.CREATE:
      this.$router.push({
        name: 'manager.control.courses.item.program.month.lessons.new',
        params: {
          courseID: this.$route.params.courseID,
          monthID: this.$route.params.monthID,
        },
      })
      break
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: `Программа обучения курса ${this.month?.course.title}`,
    }
  }
}
