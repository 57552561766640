



























































































import { Bind, Debounce } from 'lodash-decorators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'

import Confirmation from '@/components/modals/Confirmation.vue'
import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import DateTimeInput from '@/components/_uikit/controls/DateTimeInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import AuthModule from '@/store/modules/auth'
import { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  ExerciseAccessLevel,
  ExerciseCloneData, ExerciseLargeResource,
  ExerciseShortResource,
  ExerciseShortResourcePaginator,
  ExercisesFilter,
  ExercisesFilterType,
} from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'
import { convertDateToMSK, formatDate } from '@/utils/functions'

@Component({
  components: {
    Confirmation,
    CourseSearch,
    DateTimeInput,
    Select,
    TableFooter,
    Tag,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class SelectExerciseTemplateModal extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private courseId!: number

  @Prop({ default: null })
  private subjectId!: number

  @Prop({ default: null })
  private groupId!: number

  @Prop({ default: false })
  private isPublic!: boolean

  @Prop({ default: false })
  private isPersonal!: boolean

  @Prop({ default: false })
  private multipartTemplateMode!: boolean

  @Prop({ default: '' })
  private month!: string

  // Можно ли ставить дедлайн раньше текущего времени или даты выдачи
  private isDeadlineEarlierNow = process.env.VUE_APP_IS_DEADLINE_EARLIER_NOW === '1'

  private filter: ExercisesFilter = GET_DEFAULT_TABLE_FILTER()
  private footerOptions = tableFooterOptions

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private exercises: ExerciseShortResourcePaginator = GET_DEFAULT_PAGINATOR()
  private selected: ExerciseShortResource[] = []
  private form: ExerciseCloneData = {
    deadlineAt: '',
    spendingAt: '',
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get taskTypes () {
    return DictionaryModule.taskTypes
  }

  private get accessTypes () {
    return DictionaryModule.exerciseAccessLevels
  }

  private get headers () {
    if (this.isPersonal) {
      return [
        { cellClass: 'cell-icon cell-icon_first', text: '', value: 'data-table-select' },
        { sortable: false, text: '#', value: 'id' },
        { sortable: false, text: 'Задание', value: 'taskType' },
        { sortable: false, text: 'Название', value: 'task' },
        { sortable: false, text: 'Предмет', value: 'subject.name' },
        { sortable: false, text: 'Автопроверка', value: 'autoCheck' },
      ]
    }
    return [
      { cellClass: 'cell-icon cell-icon_first', text: '', value: 'data-table-select' },
      { sortable: false, text: '#', value: 'id' },
      { sortable: false, text: 'Задание', value: 'taskType' },
      { sortable: false, text: 'Тип', value: 'accessLevel' },
      { sortable: false, text: 'Название', value: 'task' },
      { sortable: false, text: 'Предмет', value: 'subject.name' },
      { sortable: false, text: 'Автопроверка', value: 'autoCheck' },
    ]
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private handleFilter (field: ExercisesFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  @Bind
  @Debounce(300)
  private searchExercises () {
    ManagerExercisesModule.searchExercises(this.filter)
      .then((response: ExerciseShortResourcePaginator) => {
        this.exercises = Object.assign({}, response)
      })
      .catch(this.notifyError)
  }

  private cloneOneExercise() {
    ManagerExercisesModule.cloneExercise({
      exerciseUUID: this.selected[0].uuid,
      params: {
        ...this.form,
        deadlineAt: convertDateToMSK(this.form.deadlineAt as string, this.isLocalTimezone),
        spendingAt: convertDateToMSK(this.form.spendingAt as string, this.isLocalTimezone),
      },
    })
      .then((response: ExerciseLargeResource) => {
        this.selected = []
        this.notifySuccess('Домашнее задание добавлено в курс')
        this.$emit('add-exercise', response.uuid)
        this.isPublic && this.$bus.$emit('add-exercise-from-template')
        this.handleClose()
      })
      .catch(this.notifyError)
  }

  private cloneMultipleExercises() {
    ManagerExercisesModule.cloneManyExercises({
      courseId: this.form.courseId,
      deadlineAt: convertDateToMSK(this.form.deadlineAt as string, this.isLocalTimezone),
      exerciseUuids: this.selected.map(item => item.uuid),
      masterGroupId: this.form.masterGroupId,
      spendingAt: convertDateToMSK(this.form.spendingAt as string, this.isLocalTimezone),
    })
      .then((response: ExerciseLargeResource) => {
        this.selected = []
        this.notifySuccess('Домашнее задание добавлено в курс')
        this.$emit('add-exercise', response.uuid)
        this.isPublic && this.$bus.$emit('add-exercise-from-template')
        this.handleClose()
      })
      .catch(this.notifyError)
  }

  private handleSelect () {
    if (this.selected.length) {
      const form: any = this.$refs.form

      form.validate()
        .then(async (result: boolean) => {
          if (result) {
            if (this.month && formatDate(this.form.spendingAt as string, 'MM') !== formatDate(this.form.deadlineAt as string, 'MM')) {
              this.confirm.open(
                'Конфликт даты',
                `Дата сдачи данного задания <span class="text-body-3 secondary--text">${formatDate(this.form.deadlineAt as string, 'dd MMM yyyy, HH:ss', this.isLocalTimezone)}</span>
                           не соответствует заполняемому месяцу <span class="text-body-3 secondary--text">${formatDate(this.month, 'LLLL')}</span> в программе курса.
                           Вы действительно хотите сохранить с текущей датой? После сохранения задание отобразится в месяце, указанном в форме.`,
                {
                  buttonConfirmText: 'Сохранить',
                  skin: 'secondary',
                },
              )
                .then(() => {
                  this.multipartTemplateMode ? this.cloneMultipleExercises() : this.cloneOneExercise()
                })
                .catch(() => {return})
            } else {
              this.multipartTemplateMode ? this.cloneMultipleExercises() : this.cloneOneExercise()
            }
          } else {
            this.notifyError('Выберите дату и время выдачи')
          }
        })
    } else {
      this.notifyError('Выберите задание')
    }
  }

  private handleClose () {
    this.$emit('update:visible', false)
  }

  @Watch('multipartTemplateMode')
  observerChangingTemplateMode() {
    this.selected = []
  }

  @Watch('visible')
  private watchVisible (value: boolean) {
    if (value) {
      this.filter = {
        ...this.filter,
        accessLevel: this.isPublic ? ExerciseAccessLevel.PUBLIC : this.isPersonal ? ExerciseAccessLevel.PERSONAL : undefined,
        subjectId: this.subjectId || undefined,
      }
      this.form = {
        courseId: this.courseId,
        masterGroupId: this.groupId || undefined,
        spendingAt: '',
      }
    }
  }

  @Watch('filter', { deep: true })
  private watchFilter () {
    this.searchExercises()
  }
}
