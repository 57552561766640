import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  ExerciseCloneData,
  ExerciseShortResourcePaginator,
  ExerciseStore,
  TaskStoreRequest,
  ExercisesFilter,
  ManagerExercisesExerciseUuidClonePostRequest,
  ManagerTasksPostRequest,
  ManagerTasksTaskUuidDeleteRequest,
  ManagerTemplatesExercisesExerciseUuidDeleteRequest,
  ManagerTemplatesExercisesExerciseUuidGetRequest,
  ManagerTemplatesExercisesExerciseUuidPostRequest,
  ManagerTemplatesExercisesGetRequest,
  ManagerTemplatesExercisesPostRequest,
  ManagerExercisesClonePostRequest,
  ExerciseCloneManyData,
  ExerciseLargeResource,
  ManagerCoursesSelectGetRequest,
  ManagerCoursesSelectGetParams,
  ManagerTemplatesExercisesExerciseUuidClonePostRequest,
  ExerciseCloneToManyData,
  ManagerTemplatesExercisesExerciseUuidListClonesGetRequest, ManagerTemplatesExercisesExerciseUuidCloneBankPostRequest,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Работа с банком ДЗ
 * - Получение списка контейнеров заданий банка (exercises)
 * - CRUD контейнера задания банка ДЗ (exercise)
 * - Управление вложенными в контейнер заданиями (tasks)
 */

@Module({
  dynamic: true,
  name: 'ManagerExercises',
  namespaced: true,
  store,
})
class ManagerExercises extends VuexModule {
  // ---------------------------- Exercises ---------------------------- >>
  // Filter
  exercisesFilter: ExercisesFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setExercisesFilter (payload: ExercisesFilter) {
    this.exercisesFilter = Object.assign({}, payload)
  }

  // Entities
  exercises: ExerciseShortResourcePaginator = GET_DEFAULT_PAGINATOR()
  exercise: ExerciseLargeResource | null = null

  @Mutation
  setExercise(payload: ExerciseLargeResource | null) {
    this.exercise = payload
  }

  @Mutation
  setExercises (payload: ExerciseShortResourcePaginator) {
    this.exercises = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchExercises () {
    const { data } = await ManagerTemplatesExercisesGetRequest(tableOptionsToParams(this.exercisesFilter))
    this.setExercises(data)
    return data
  }

  @Action({ rawError: true })
  async searchExercises (payload: ExercisesFilter) {
    const { data } = await ManagerTemplatesExercisesGetRequest(tableOptionsToParams(payload))
    return data
  }

  // ---------------------------- Exercise ---------------------------- >>
  // exercise: any = null
  //
  // @Mutation
  // setExercise (payload: ExerciseLargeResource) {
  //   this.exercise = Object.assign({}, payload)
  // }

  @Action({ rawError: true })
  async fetchExercise (exerciseUUID: string) {
    const { data } = await ManagerTemplatesExercisesExerciseUuidGetRequest(exerciseUUID)
    this.setExercise(data)
    return data
  }

  @Action({ rawError: true })
  async saveExercise (payload: { exerciseUUID?: string, params: ExerciseStore }) {
    if (payload.exerciseUUID) {
      const { data } = await ManagerTemplatesExercisesExerciseUuidPostRequest(payload.exerciseUUID, payload.params)
      this.setExercise(data)
      return data
    } else {
      const { data } = await ManagerTemplatesExercisesPostRequest(payload.params)
      this.setExercise(data)
      return data
    }
  }

  @Action({ rawError: true })
  async cloneExercise (payload: { exerciseUUID: string, params?: ExerciseCloneData }) {
    const { data } = await ManagerExercisesExerciseUuidClonePostRequest(payload.exerciseUUID, payload.params)
    return data
  }

  @Action({ rawError: true })
  async cloneManyExercises(payload: ExerciseCloneManyData) {
    const { data } = await ManagerExercisesClonePostRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async deleteExercise (exerciseUUID: string) {
    const { data } = await ManagerTemplatesExercisesExerciseUuidDeleteRequest(exerciseUUID)
    this.setExercise(null)
    return data
  }

  // ---------------------------- Tasks manage ---------------------------- >>

  @Action({ rawError: true })
  async saveTask (payload: TaskStoreRequest) {
    const { data } = await ManagerTasksPostRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async deleteTask (taskUUID: string) {
    const { data } = await ManagerTasksTaskUuidDeleteRequest(taskUUID)
    return data
  }

  @Action({ rawError: true })
  async fetchCourses(payload: ManagerCoursesSelectGetParams) {
    const { data } = await ManagerCoursesSelectGetRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async duplicateExercise(payload: { body: ExerciseCloneToManyData, exerciseUuid: string }) {
    const { data } = await ManagerTemplatesExercisesExerciseUuidClonePostRequest(payload.exerciseUuid, payload.body)
    return data
  }

  @Action({ rawError: true })
  async fetchCloneCoursesListExercise(uuid: string) {
    const { data } = await ManagerTemplatesExercisesExerciseUuidListClonesGetRequest(uuid)
    return data
  }

  @Action({ rawError: true })
  async duplicateExerciseToBank(exerciseUUID: string) {
    const { data } = await ManagerTemplatesExercisesExerciseUuidCloneBankPostRequest(exerciseUUID)
    return data
  }
}

const ManagerExercisesModule = getModule(ManagerExercises)

export default ManagerExercisesModule
