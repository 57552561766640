var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.month)?_c('PageContainer',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('PageHeader',{attrs:{"title":"Программа обучения","breadcrumbs":_vm.breadcrumbs,"back":-1}})]},proxy:true},{key:"content",fn:function(){return [_c('VCard',{staticClass:"month-wrapper"},[_c('div',{staticClass:"month-wrapper__header"},[_c('div',{staticClass:"month-wrapper__info"},[_c('div',{staticClass:"text-h3-1"},[_vm._v(_vm._s(_vm.monthName))]),_c('div',{staticClass:"month-wrapper__tags"},[_c('Tag',{staticClass:"space-r-8",attrs:{"color":"teal","type":_vm.month.lessonsCount > 0 ? 'fill' : 'bright'}},[_c('span',{directives:[{name:"declension",rawName:"v-declension",value:({ count: _vm.month.lessonsCount, list: ['урок', 'урока', 'уроков'] }),expression:"{ count: month.lessonsCount, list: ['урок', 'урока', 'уроков'] }"}]})]),_c('Tag',{attrs:{"color":"orange","type":_vm.month.exerciseCount > 0 ? 'fill' : 'bright'}},[_c('span',{directives:[{name:"declension",rawName:"v-declension",value:({ count: _vm.month.exerciseCount, list: ['задание', 'задания', 'заданий'] }),expression:"{ count: month.exerciseCount, list: ['задание', 'задания', 'заданий'] }"}]})]),_c('div',{staticClass:"display-mb"},[(_vm.$route.name === 'manager.control.courses.item.program.month.lessons' && _vm.hasPermission(_vm.Permission.COURSE_LESSONS_EDIT))?_c('VMenu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g({attrs:{"color":"teal","dark":""}},on),[_vm._v("Новый урок")])]}}],null,false,3394292501)},[_c('VList',_vm._l((_vm.lessonsMenu),function(item,index){return _c('VListItem',{key:((item.value) + "-" + index),on:{"click":function($event){return _vm.handleLessonsMenuClick(item)}}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e()],1),_c('div',{staticClass:"display-mb"},[(_vm.$route.name === 'manager.control.courses.item.program.month.exercises' && _vm.hasPermission(_vm.Permission.COURSE_EXERCISES_EDIT))?_c('VMenu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g({attrs:{"color":"teal","dark":""}},on),[_vm._v("Новое задание")])]}}],null,false,2242158712)},[_c('VList',_vm._l((_vm.exercisesMenu),function(item,index){return _c('VListItem',{key:((item.value) + "-" + index),on:{"click":function($event){return _vm.handleExerciseMenuClick(item)}}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e()],1)],1)]),_c('LinkTabs',{attrs:{"tabs":_vm.tabs,"transparent":true},scopedSlots:_vm._u([{key:"additional",fn:function(){return [_c('div',{staticClass:"display-none-mb"},[(_vm.$route.name === 'manager.control.courses.item.program.month.lessons' && _vm.hasPermission(_vm.Permission.COURSE_LESSONS_EDIT))?_c('VMenu',{staticClass:"space-r-8",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g({staticClass:"space-r-8",attrs:{"color":"teal","dark":""}},on),[_vm._v("Новый урок")])]}}],null,false,3021877147)},[_c('VList',_vm._l((_vm.lessonsMenu),function(item,index){return _c('VListItem',{key:((item.value) + "-" + index),on:{"click":function($event){return _vm.handleLessonsMenuClick(item)}}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e()],1),_c('div',{staticClass:"display-none-mb"},[(_vm.$route.name === 'manager.control.courses.item.program.month.exercises' && _vm.hasPermission(_vm.Permission.COURSE_EXERCISES_EDIT))?_c('VMenu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g({staticClass:"space-r-8",attrs:{"color":"teal","dark":""}},on),[_vm._v("Новое задание")])]}}],null,false,3471101558)},[_c('VList',_vm._l((_vm.exercisesMenu),function(item,index){return _c('VListItem',{key:((item.value) + "-" + index),on:{"click":function($event){return _vm.handleExerciseMenuClick(item)}}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e()],1)]},proxy:true}],null,false,1004611283)})],1),_c('RouterView',{key:_vm.$route.fullPath,attrs:{"month":_vm.month}}),_c('SelectExerciseTemplateModal',{attrs:{"visible":_vm.showExerciseTemplateModal,"courseId":_vm.month.course.id,"subjectId":_vm.month.course.subject.value,"isPublic":true,"month":_vm.month.date},on:{"update:visible":function($event){_vm.showExerciseTemplateModal=$event}}}),_c('SelectLessonTemplateModal',{attrs:{"visible":_vm.showLessonsTemplateModal,"courseId":_vm.month.course.id},on:{"update:visible":function($event){_vm.showLessonsTemplateModal=$event},"closed-modal":_vm.fetchLessons}})],1)]},proxy:true}],null,false,1368882580)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }